/* .game-layout {
    display: flex;
    align-items: stretch;
}

.main-image, .thumbnails {
    display: flex;
    flex-direction: column;
}

.main-image {
    flex: 0.3;
}

.thumbnails {
    flex: 0.7;
    gap: 10px;
}

.main-image img {
    width: 100%;
    object-fit: cover;
    max-height: 100%; 
}

.thumbnails {
    gap: 10px;
}

.thumbnail-row {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex: 1;
}

.thumbnail-row > img {
    width: calc(33.33% - 10px); 
    height: 100%;
    object-fit: cover;
} */

/* .box {
    width: 1000px;
    margin: 50px auto;
    overflow: hidden;
}

img {
    width: 300px;
}

.box>div {
    float: left;
}

.left img {
    height: 327px;
    margin-right: 10px;
} */
/* .game-layout {
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 1125px; 
}

.main-image, .thumbnails {
    display: flex;
    flex-direction: column;
}

.main-image {
    flex: 0.3;
}

.thumbnails {
    flex: 0.7;
    gap: 10px;
    overflow: hidden;
}

.main-image img {
    width: 100%;
    object-fit: cover;
    height: 750px; 
}

.thumbnails {
    gap: 10px;
}

.thumbnail-row {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    height: 375px; 
}

.thumbnail-row > img {
    width: calc(33.33% - 10px); 
    height: 100%;
    object-fit: cover;
} */


/* .row_Image{
    display: flex;
    align-items: stretch;
} */

.game-layout {
    display: flex;
    align-items: stretch; /* Ensure the children stretch to the same height */
    width: 100%;
    justify-content: space-between;
    padding:0 .3rem;
}

.main-image {
    width: 33%;
    flex-shrink: 0;  /* Prevent it from shrinking if the container gets too small */
flex: 1;
}


.main-image img {
    width: 100%;
    height: auto;
    display: block;
}

.thumbnails {
    width: 60.5%;
    display: flex;
    flex-direction: column;
    padding:0 0 0 10px;
}

.thumbnail-row {
    flex: 1 1;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.thumbnail-row img {
    flex: 1;
    object-fit: cover;
    height: 100%;
}

/* template03 */
#layout_03 .card_container{
padding: 0;
}

#layout_03 .card_wrap{
    height: 100%;
}


#layout_03 .lazy-load-image-background.blur.lazy-load-image-loaded{
    height: 100%;
    width: 100%;
}

#layout .card_wrap img{
    height: 100%;
}


.template_row{
    padding:0 .5rem;
}

/* IMAGE SIZE 1x1 */

.grid-container {
    display: grid;
    grid-template-columns: 2fr repeat(5, 1fr); 
    grid-auto-rows: 1fr;
    gap: 10px;
  }
  
  .large-game {
    grid-column: 1 / 2; 
    grid-row: 1 / span 2; 
  }
  
  .large-game img {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover; 
  }
  
  .small-game {
    width: 100%;
    position: relative;
  }
  
  .small-game::before {
    content: '';
    display: block;
    /* padding-top: 100%;  */
  }
  
  .small-game img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  #layout_03 .card_container .card_action_hover .card_action_wrap {
    width: 100%;
    height: 100%;
}